import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

function Result(props: any) {
    const {
        data,
        handleClickShowAllMapResult,
        isShowMapResult,
        headerTable
    } = props;

    return (
        <div className="render-map-child-container pt-2 pl-2 pr-2">
            <div className="d-flex align-items-center justify-content-between pt-2 pb-2 pl-2">
                <div className="map-result-number">
                    Có <b>{data.length}</b> kết quả
                </div>
                <div className="map-result-view">
                    {!isShowMapResult ? (
                        <img
                            src={require("../../../../assets/icon/last.svg")}
                            alt="Last"
                            className="img-last"
                            onClick={handleClickShowAllMapResult}
                        ></img>
                    ) : (
                        <img
                            src={require("../../../../assets/icon/last.svg")}
                            alt="Last"
                            className="img-last-reverse"
                            onClick={handleClickShowAllMapResult}
                        ></img>
                    )}
                    <img
                        src={require("../../../../assets/icon/close-black.svg")}
                        alt="Close"
                        className="img-close ml-3"
                    ></img>
                </div>
            </div>

            <table className="table table-map-result">
                <thead>
                <tr>
                    {headerTable &&
                    headerTable.length > 0 &&
                    headerTable.map((item: any, index: number) => (
                        <th
                            scope="col"
                            key={index}
                            className="text-truncate bg-primary text-white"
                        >
                            {item.alias}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data &&
                data.length > 0 &&
                data.map((item: any, index: number) => (
                    <tr key={index}>
                        {headerTable &&
                        headerTable.length > 0 &&
                        headerTable.map((item1: any, index: number) => (
                            <th scope="row" key={index}>
                                {item[item1.col]}
                            </th>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

const mapStateToProps = (state) => ({
    openLayerController: state.openlayer.openLayerController,
    headerTable: state.mapData.headerTable,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Result);