import {useEffect,useState} from "react";
import {getListYear} from "../../../../common/utility";
import * as objReport from "../../../../redux/store/report/report.store";
import NumberFormat from 'react-number-format';
import {bindActionCreators} from "redux";
import * as appActions from "../../../../core/app.store";
import {connect} from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Notification from "../../../../common/notification-service"
import "./report.scss";
let React = require('react');
let ReactHighcharts = require('react-highcharts');

function Report(props) {
    const {mapReportStatus} = props;
    const { closeReport } = props;
    const [config, setConfig] = useState('');
    const [dataList, setDataList] = useState({});
    const [reportUrl, setReportUrl] = useState(props.mapReportStatus.reportUrl);
    const [defaultChart, setDefaultChart] = useState(props.mapReportStatus.defaultChart);
    const [reportName, setReportName] = useState(props.mapReportStatus.defaultChart);
    const [carrer, setCarrer] = useState('');
    const [fromYear, setFromYear] = useState(2015);
    const [defaultFromYear, setDefaultFromYear] = useState(2015);
    const [toYear, setToYear] = useState(2030);
    const [defaultToYear, setDefaultToYear] = useState(2030);
    const [hasYearCondition, setHasYearCondition] = useState(true);
    const [reportListYear, setReportListYear] = useState([]);
    const [reportListYearData, setReportListYearData] = useState([]);
    const [reportListCareer, setReportListCareer] = useState([]);
    const [listCareer, setListCareer] = useState([]);
    const [listCareerTitle, setListCareerTitle] = useState([]);
    const [mapLoading, setMapLoading] = useState(false);
    const [listChart, setListChart] = useState([]);

    useEffect(()=> {
        getListChartByType(mapReportStatus);
        getReport();
    },[]);

    const getListChartByType = (data) => {
        return new Promise((resolve,reject) => {
            objReport.getListChartByType(data)
                .then(
                    (res) => {
                        if(res.content && res.content && res.content.length >0){

                            setListChart(res.content);
                        }
                        resolve(res);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }

    const changeReport = () => {
        new Promise((resolve,reject) => {
            try{
                setDefaultChart(reportName);
                resolve(reportName);
            }catch (e) {
                reject(e);
            }
        }).then((res) => {
            getReport();
        })
    }

    const getReport = () => {
        if(!reportUrl){
            Notification.error("Vui lòng chon biểu đồ");
            return false;
        }
        if(!fromYear){
            Notification.error("Vui lòng chọn: Từ năm");
            return false;
        }
        if(!toYear){
            Notification.error("Vui lòng chọn: Đến năm");
            return false;
        }

        if(fromYear > toYear){
            Notification.error("Từ năm phải nhỏ hơn Đến năm");
            return false;
        }else{
            setMapLoading(true);
            let data = {
                FromYear: parseInt(fromYear),
                ToYear: parseInt(toYear)
            }
            if (carrer) {
                let ids = [];
                ids.push(parseInt(carrer));
                data.ids = ids;
            }
            return new Promise((resolve, reject) => {
                objReport.GetReport(reportUrl, data).then(
                    (res) => {
                        if(res.content){
                            if (res.content.Charts) {
                                setConfig(res.content.Charts);
                            }else{
                                setConfig('');
                            }

                            if (res.content.TableData) {
                                analyseTableData(res.content.TableData);
                            }
                        }else{
                            setConfig('');
                            setDataList([]);
                            setReportListCareer([]);
                        }
                        resolve(res);
                    },
                    (err) => {
                        setMapLoading(false);
                        setConfig('');
                        setDataList([]);
                        setReportListCareer([]);
                        reject(err);
                    }
                );
            }).then(() => {
                setMapLoading(false);
            }).finally(() => {
                setMapLoading(false);
            });
        }
    }

    function analyseTableData(tableData) {
        let listYear = [];
        let listCareer = [];
        let listYearData = [];
        if(tableData && tableData.length >0){
            var firstRow = tableData[0];
            firstRow && Array.isArray(firstRow) && firstRow.length > 0 && firstRow.map((item) => {
                if (item && item.key && item.key != 'colName') {
                    listYear.push(item.key);
                }
            });

            for(let i=0; i<tableData.length;i++){
                listCareer.push(tableData[i][0].value);
            }

            if(listCareer && listCareer.length >0
                && listYear && listYear.length >0){
                listCareer.map((career) => {
                    listYearData[career] = getCareerYearData(career,tableData);
                });
            }

            setReportListYear(listYear);
            setReportListYearData(listYearData);
            setReportListCareer(listCareer);
            setDataList(tableData);
        }else{
            setDataList([]);
            setReportListCareer([]);
        }
    }

    function getCareerYearData(career,tableData) {
        var yearData = [];
        for(let i=0;i<tableData.length;i++){
            let careerRow = tableData[i];
            if(careerRow && Array.isArray(careerRow) && careerRow.length > 0 && careerRow[0].value == career){
                careerRow.map((item) => {
                    if(item.key != 'colName'){
                        yearData.push(item.value);
                    }
                })
            }
        }
        return yearData;
    }

    function buildComboboxChart(comboboxId,defaultText) {
        return (
            <select id={comboboxId}
                    onChange={selectChart}
            >
                {defaultText && (
                    <option value="">{defaultText}</option>
                )}
                {
                    listChart && Array.isArray(listChart) && listChart.length >0 && (
                        listChart.map((item) => (
                            item && item.code && item.code != defaultChart ? (
                                <option value={item.code}>{item.title}</option>
                            ):(
                                <option selected="selected" value={item.code}>{item.title}</option>
                            )
                        ))
                    )
                }
            </select>
        )
    }

    function buildComboboxCareer(comboboxId) {
        return (
            <select id={comboboxId} onChange={selectCarrer}>
                {listCareerTitle && (
                    <option value="">{listCareerTitle}</option>
                )}
                {
                    listCareer && Array.isArray(listCareer) && listCareer.length >0 && (
                        listCareer.map((item,index) => (
                            <option value={item.id}>{item.name}</option>
                        ))
                    )
                }
            </select>
        )
    }

    function buildComboboxFromYear(comboboxId,comboboxName) {
        let listYear = getListYear(new Date().getFullYear() - 6,new Date().getFullYear() + 10);
        return (
            <select id={comboboxId} onChange={selectFromYear}>
                <option value="">{comboboxName}</option>
                {
                    Array.isArray(listYear) && listYear.length >0 && (
                        listYear.map((item) => (
                            item && item != defaultFromYear ? (
                                <option value={item}>{item}</option>
                            ):(
                                <option selected="selected" value={item}>{item}</option>
                            )
                        ))
                    )
                }
            </select>
        )
    }

    function buildComboboxToYear(comboboxId,comboboxName) {
        let listYear = getListYear(new Date().getFullYear() - 6,new Date().getFullYear() + 10);
        return (
            <select id={comboboxId} onChange={selectToYear}>
                <option value="">{comboboxName}</option>
                {
                    Array.isArray(listYear) && listYear.length >0 && (
                        listYear.map((item) => (
                            item && item != defaultToYear ? (
                                <option value={item}>{item}</option>
                            ):(
                                <option selected="selected" value={item}>{item}</option>
                            )
                        ))
                    )
                }
            </select>
        )
    }

    function selectChart(event) {
        let reportName = event.target.value;
        if(reportName && listChart && Array.isArray(listChart) && listChart.length >0){
            let url = '/api/cms/Charts/' + reportName;
            setReportUrl(url);
            setReportName(reportName);
            listChart.map((chart) => {
                if(chart.code === reportName){
                    if(chart.hasCondition === true){
                        let listDataFilter = chart.filterConditionData.list_data_filter;
                        if(listDataFilter && Array.isArray(listDataFilter) && listDataFilter.length >0){
                            setListCareer(listDataFilter);
                            setListCareerTitle("Chọn "+chart.conditionName);
                        }else{
                            setListCareer([]);
                            setListCareerTitle('');
                        }
                    }else{
                        setListCareer([]);
                        setListCareerTitle('');
                    }
                    setHasYearCondition(chart.hasYearCondition);
                }

            });
        }else{
            Notification.error("Vui lòng chọn biểu đồ");
        }
    }

    function selectCarrer(event) {
        setCarrer(event.target.value);
    }

    function selectFromYear(event) {
        setFromYear(event.target.value);
    }

    function selectToYear(event) {
        setToYear(event.target.value);
    }
    
    function getTableHeader(listYear,chartName) {
        var listTHYear = [];
        function buildListYear() {
            listYear && Array.isArray(listYear) && listYear.length >0 && listYear.map((item) => {
                listTHYear.push(<th>{item}</th>);
            });
            return listTHYear;
        }
        return (
            <tr>
                <th>STT</th>
                {chartName && chartName != 'EconomicAnticipateChart' ? (
                    <th>Ngành nghề</th>
                ):(
                    <th>Hạng mục</th>
                )}
                {buildListYear(dataList)}
            </tr>
        )
    }
    
    function getTableRow(listCareer,listYearData,chartName) {
        function buildListCareer(listCareer) {
            const listRow = [];
            if(listCareer && listCareer.length >0){
                for(let i=0; i<listCareer.length;i++){
                    let careerName      = listCareer[i];
                    let careerYearData  = listYearData[careerName];
                    listRow.push(
                        <tr>
                            <td align="right">{i + 1}</td>
                            <td>{careerName}</td>
                            {careerYearData && careerYearData.length >0 && careerYearData.map((yearData) => (
                                <td align="right">
                                    {chartName && chartName != 'EconomicAnticipateChart' ? (
                                        <NumberFormat value={yearData} displayType={'text'} thousandSeparator={true} />
                                    ):(
                                        <span>{yearData}</span>
                                    )}
                                </td>
                            ))}
                        </tr>
                    );
                }
            }
            return listRow;
        }
        return (
            buildListCareer(listCareer)
        )
    }

    return (
        <LoadingOverlay
            active={mapLoading}
            spinner
            text='Đang tải dữ liệu, vui lòng đợi'
        >
            <div className="report-content-box">
                <div className="close-icon" onClick={closeReport}>
                    <FontAwesomeIcon
                        icon={faTimesCircle}
                        color="#1b1b1b"
                        fontSize="fa-2x"
                    />
                </div>
                <div className="search-box">

                    <div className="select-chart-box">
                        {listChart && Array.isArray(listChart) && listChart.length >0 && (
                            buildComboboxChart('select-chart','Chọn biểu đồ')
                        )}
                    </div>
                    <div className="select-carrer-box">
                        {listCareer && Array.isArray(listCareer) && listCareer.length >0 && (
                            buildComboboxCareer('select-carrer')
                        )}
                    </div>
                    {hasYearCondition && (
                        <span>
                            <div className="select-from-year-box">
                                {buildComboboxFromYear('from-year', 'Từ năm')}
                            </div>
                            <div className="select-to-year-box">
                                {buildComboboxToYear('to-year', 'Đến năm')}
                            </div>
                        </span>
                    )}
                    <div className="search-view-box">
                        <button onClick={() => changeReport()} type="button" className="btn btn-sm btn-primary">Xem</button>
                    </div>
                    <div className="cb"></div>
                </div>
                {dataList && Array.isArray(dataList) && dataList.length >0 ? (
                    <span>
                        {defaultChart && defaultChart != 'EconomicAnticipateChart' ? (
                            <div className="report-render-box">
                                <div className="chart-box">
                                    {dataList && Array.isArray(dataList) && dataList.length >0
                                        && config != '' ? (
                                        <ReactHighcharts config={config} id="resultBox"></ReactHighcharts>
                                    ):(
                                        <div className="report-no-data">Chưa có dữ liệu biểu đồ</div>
                                    )}
                                </div>
                                <div className="table-data-box">
                                    <table>
                                        <tbody>
                                        {getTableHeader(reportListYear,'')}
                                        {getTableRow(reportListCareer,reportListYearData)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ):(
                            <div className="report-table-only-box">
                                <div className="table-data-box">
                                    <table>
                                        <tbody>
                                        {getTableHeader(reportListYear,defaultChart)}
                                        {getTableRow(reportListCareer,reportListYearData,defaultChart)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            )}
                        <div className="cb"></div>
                    </span>
                ):(
                    <div className="report-render-box">
                        {dataList && Array.isArray(dataList) && dataList.length >0 && config != '' ? (
                            <ReactHighcharts config={config} id="resultBox"></ReactHighcharts>
                        ):(
                            <div className="report-no-data">Chưa có dữ liệu biểu đồ</div>
                        )}
                    </div>
                )}
            </div>
        </LoadingOverlay>
    );
}
const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    mapReportStatus: state.mapToolsPanel.mapReport,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Report);
