import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { geolocated } from "react-geolocated";
import OpenlayerController from "../../../../models/open-layer/openlayer-controller-custom.model";
import { CoordsModel } from '../../../../models/planning-map-view/top-right-panel-models';
import {isMobile} from 'react-device-detect';


interface InitProps {
    classCustom?: string;
    openLayerController?: OpenlayerController;
    onShow?: Function;
    show?: boolean;
    toggleLeftNavBarAction: Function
}

function SearchByMyPosition(props: InitProps) {
    const { onShow } = props;
    const [currentPosition, setCurrentPosition] = useState<CoordsModel | undefined>(undefined);
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position: any) => {
            setCurrentPosition(position.coords);
        });
    }, [])

    function handleClickSearchAddress() {
        onShow && onShow(!props.show);
         if (!currentPosition) {
             navigator.geolocation.getCurrentPosition((position: any) => position && position.coords && props.openLayerController?.placeSearch([position.coords.longitude, position.coords.latitude]));
         } else {
             props.openLayerController?.placeSearch([currentPosition.longitude, currentPosition.latitude]);
         }
         if(isMobile){
             props.toggleLeftNavBarAction();
         }
    }

    return (
        <button onClick={handleClickSearchAddress} className="bg-transparent border-0">
            <img src={require("../../../../assets/icon/place.svg")} alt="Locate" />
        </button>
    )
}
const mapStateToProps = (state: any) => ({
    openLayerController: state.openlayer.openLayerController

})
export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps)(SearchByMyPosition));