export const getListYear = (fromYear,toYear) => {
    let i;
    var listYear = [];
    for (i = fromYear; i < toYear; i++) {
        let year = i;
        if (i < 10) {
            year = '0' + i;
        }
        listYear.push(year);
    }
    return listYear;
}
