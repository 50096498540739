import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetDetailIntroduce = (keyword) => {
  let requestUrl = ApiUrl.GetDetailIntroduce + '/' +keyword;

  return service
    .get(requestUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};


