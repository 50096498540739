import React, {useEffect, useState, useRef, PureComponent} from "react";
import { useHistory } from "react-router-dom";

import {connect, useSelector} from "react-redux";
import { bindActionCreators } from "redux";
import './document.scss';
import * as documentAction from "../../../../redux/store/doc/document.store";
import * as appActions from "../../../../core/app.store";
import DocTreeView from './doctree.view';

function DocumentView(props: any) {
  let history = useHistory();
  const {
    showLoading,
  } = props;

  const [documents, setDocuments] = useState([]);
  const [detailModels, setDetailModel] = useState();
  const [planningRelationShips, setPlanningRelationShips] = useState([]);
  const [nameFolderTitle, setNameFolderTitle] = useState([]);
  const typeName = "Folder";
  const [treeData, setTreeData] = useState({});
  const [cursor, setCursor] = useState(false);

  const getTreeDocData = (docList:any) => {
    var docNode : {id:number,text:string,children:any}[] = [];
    docList.map((node:any) => {
        Array.isArray(node.childrent) && node.type == 0 && (
            node.childrent.length >0 ? (
                docNode.push({
                    id: node.id,
                    text: node.name,
                    children: getTreeDocData(node.childrent)
                })
            ):(
                docNode.push({
                    id: node.id,
                    text: node.name,
                    children: []
                })
            )
        )
    })
    return docNode;
  }

  useEffect(() => {
    GetAllDocumentFolder();
  }, []);

  const GetAllDocumentFolder = () => {
    showLoading(true);
    documentAction
        .GetAllDocumentFolder()
        .then((result) => {
          if (result && result.content) {
            setDetailModel(result.content);
            setDocuments(
                result.content && result.content.length > 0
                    ? result.content
                    : []
            );
            setPlanningRelationShips(
                result.content.planningRelationShips &&
                result.content.planningRelationShips.length > 0
                    ? result.content.planningRelationShips
                    : []
            );
          }
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
        });
  };

  return (
      <div>
          <div className="render-map-child-container pl-2 pr-2">
              <details>
                  <summary className="mb-1 map-menu-level1 document-box">
                      Văn bản - Báo cáo - Thuyết minh
                      <img
                          className="position-absolute icon-dropdow right-position"
                          src={require("../../../../assets/icon/arrow-up.png")}
                          alt="Arrow Down"
                      />
                      <img
                          className="position-absolute icon-dropright right-position"
                          src={require("../../../../assets/icon/arrow-down.png")}
                          alt="Arrow Right"
                      />
                  </summary>
                  <DocTreeView
                      documentFolderList={documents}
                  />
              </details>
          </div>
      </div>
  );
}

const mapStateToProps = (state: any) => ({
  openLayerController: state.openlayer.openLayerController,
});
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  showLoading: appActions.ShowLoading,
}, dispatch);

export default (connect(mapStateToProps, mapDispatchToProps)(DocumentView));
