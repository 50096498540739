const UrlCollect = {
  Home: "/",
  SpecializedPlanning: "/quy-hoach-chuyen-nganh",
  SyntheticPlanning: "/tong-hop-quy-hoach",
  GeneralPlanning: "/quy-hoach-chung",
  SubdivisionPlanning: "/quy-hoach-phan-khu",
  DetailedPlanning: "/quy-hoach-chi-tiet",
  PlanningAnnouncement: "/cong-bo-quy-hoach",
  PlanningIntro : "/gioi-thieu-quy-hoach",
  News: "/news",
  DocumentManager: "/quan-ly-tai-lieu",
  RecordsManager: "/quan-ly-ho-so",
  Contact: "/lien-he",
  PlanningMapViewDetail: "/ban-do-quy-hoach/",
  PlanningMap: "/ban-do-quy-hoach",

  //-- User
  Login: "/dang-nhap",
  Register: "/dang-ky",
  ForgotPassword: "/quen-mat-khau",
  ConfirmCode: "/xac-nhan",
  ResetPassword: "/dat-lai-mat-khau",

  //-- Xin y kien cong dong
  PleaseConsultTheCommunity: "/y-kien-cong-dong",
  PleaseComment: "/xin-y-kien",
  SummaryIdeas: "/y-kien-tong-hop",

  //-- Tin tuc
  NewsList: "/tin-tuc",
  NewsDetail: "/tin-tuc-chi-tiet",

  //-- Error
  Page500: '/page-500/',

  //-- Admin
  ProcessBoardPlanning:"/quy-trinh-cong-bo-quy-hoach"
};

export default UrlCollect