import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetSlideShow = () => {
  return (dispatch) => {
    return service
      .get(ApiUrl.SlideShow)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetAllPlanningByTypeId = (planningTypeId) => {
  return (dispatch) => {
    const params = new URLSearchParams();
    planningTypeId && params.append("planningTypeId", planningTypeId);
    return service
      .get(ApiUrl.GetAllPlanningByTypeId, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const GetPlanningSearchByIdName = (
  PlanningTypeById,
  pageIndex = 1,
  planningId,
  name,
  pageSize = 6
) => {
  const params = new URLSearchParams();
  PlanningTypeById && params.append("planningTypeId", PlanningTypeById);
  planningId && params.append("planingId", planningId);
  name && params.append("name", name);
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  return service
    .get(ApiUrl.PlanningSearchByIdName, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
