import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";

import * as homePageStore from "../../../redux/store/home-page/home-page.store";
import * as appActions from "../../../core/app.store";

import {
  APIUrlDefault, getUserInfo,
} from "../../../utils/configuration";

import "./link.scss";
import {Link} from "react-router-dom";

function HomeLinkView(props) {
  const { showLoading } = props;
  const isLogin = getUserInfo() ? true : false;

  return (
      <section id="home-link-box" className="navigation">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="navigation-item">
                <Link to="/y-kien-cong-dong">
                  <img src="../../images/xin-y-kien-cong-dong.png"/>
                  <span>Xin ý kiến cộng đồng</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="navigation-item">
                <Link to="/cong-bo-quy-hoach">
                  <img src="../../images/cong-bo-quy-hoach.png"/>
                  <span>Công bố quy hoạch</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="navigation-item">
                <Link to="gioi-thieu-quy-hoach">
                  <img src="../../images/tra-cuu-quy-hoach.png"/>
                  <span>Tra cứu quy hoạch</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="navigation-item">
                <a href="/quan-ly-tai-lieu/vung-tau">
                  <img src="../../images/quan-ly-ho-so.png"/>
                  <span>Quản lý hồ sơ quy hoạch</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default  (connect(mapStateToProps, mapDispatchToProps)(HomeLinkView));
