import React, { useState, useEffect } from "react";
import {bindActionCreators} from "redux";
import * as appActions from "../../../core/app.store";
import {connect} from "react-redux";
import "./note.scss";
import {
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APIUrlDefault} from "../../../utils/configuration";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";

function MapNote(props) {
    const[isOpen,setIsOpen] = useState(false);
    const[mapName,setMapName] = useState('');
    const[noteUrl,setNoteUrl] = useState('');

    const {mapNote} = props;

    function closeMapNote() {
        setIsOpen(true);
        props.HandleSaveMapNote({});
    }

    useEffect(function () {
        getNoteUrl();
    })
    function getNoteUrl() {
        if(!isEmpty(mapNote)){
            setIsOpen(true);
            setNoteUrl(APIUrlDefault+mapNote.files.filePreview);
            setMapName(mapNote.name);
        }else{
            setIsOpen(false);
        }
    }

    function isEmpty(obj) {
        for (var x in obj) { if (obj.hasOwnProperty(x))  return false; }
        return true;
    }
    
    return (
        <div className="map-note-container">
            {isOpen && (
                <div id="map-note-box" className="map-note-box open-map-note">
                    <div className="note-header">
                        <div className="close-icon" onClick={closeMapNote}>
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                color="#FFFFFF"
                            />
                        </div>
                        Chú giải bản đồ
                    </div>
                    <div className="note-content">
                        {noteUrl && (
                            <img alt={mapName} title={mapName} src={noteUrl}></img>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    mapNote: state.mapData.mapNoteInfo,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            HandleSaveMapNote:MapDataStore.SaveMapNote,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapNote);