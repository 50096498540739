import Service from "../../../api/api-service";
import {ApiUrl} from "../../../api/api-url";

const service = new Service();

export const GetReport = (reportUrl,data) => {

  return service
      .post(reportUrl, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const getListChartByLayer = (data) => {
    const params = new URLSearchParams();
    data.layerId && params.append("layerId", data.layerId);

    return service
        .get(ApiUrl.GetListChartByLayer, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const getListChartByType = (data) => {
    const params = new URLSearchParams();
    data.type >0 && params.append("type", data.type);

    return service
        .get(ApiUrl.GetListChartByType, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};


