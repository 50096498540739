import React, { useState, useEffect } from "react";
import moment from 'moment';
import {connect, useSelector} from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faTable,
    faList,
    faExclamationTriangle, faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
    faEye,
    faDownload,
    faCopy,
    faClock,
    faMap,
    faWindowClose,
    faFolderOpen
} from "@fortawesome/free-solid-svg-icons";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import FileViewer from "react-file-viewer";
import DocSearchView from './leftmenu/docsearch.view';
import DocTreeView from './leftmenu/doctree.view';

import * as appActions from "../../core/app.store";
import * as documentAction from "../../redux/store/doc/document.store";
import Pagination from "../../components/pagination/pagination.view";

import * as config from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";

import "./list.scss";
import {saveAs} from "file-saver";

function DocumentManagementDesktopView(props) {
  const {
      showLoading, folderId , setCurrentFolderId,
      paginationNumber,listDocuments,
      listBredScrums, GetListDocumentByFolder,
      dataSearch, SetDataSearch
  } = props;

  const currentPageIndex    = 1;
  const currentPageSize     = config.defaultPagesize;
  const [isShowPreview, setShowPreview] = useState(false);
  const [file, setFile] = useState();
  const [isTable, setIsTable] = useState(true);
  const [isDetail, setIsDetail] = useState(false);
  const {id} = props.match.params;

  useEffect(() => {
      let currentFolderId = parseInt(id);
      if(currentFolderId > 0){
          setCurrentFolderId(currentFolderId);
          GetListDocumentByFolder({
              parentId:currentFolderId,
              pageIndex:currentPageIndex,
              pageSize:currentPageSize
          });
      }else{
          setCurrentFolderId(0);
          GetListDocumentByFolder({
              parentId:0,
              pageIndex:currentPageIndex,
              pageSize:currentPageSize
          });
      }
      SetDataSearch({'actionType':'list'});
  }, []);

  const doPaging = (pageIndex) => {
      //console.log(dataSearch);
      if(dataSearch.actionType == 'search'){
          dataSearch.pageIndex = pageIndex;
          GetListDocumentByFolder(dataSearch);
      }else{
          GetListDocumentByFolder({
              parentId: folderId,
              pageIndex: pageIndex,
              pageSize: currentPageSize
          });
      }
  }

    const onShowFilePreview = (file) => {
        if (file) {
            setShowPreview(true);
            setFile(file);
        }
    };

    const renderFilePreview = (file) => {
        let isSupport = config.FilePreviewSupport(file.extension);
        return (
            <a
                onClick={() => {
                    isSupport
                        ? onShowFilePreview(file)
                        : ShowNotification(
                        viVN.Warning.FilePreviewNotSupport,
                        config.NotificationMessageType.Warning,
                        config.NotificationPosition.Center
                        );
                }}
                className="cursor-pointer"
            >
                <FontAwesomeIcon icon={faEye} />Xem
            </a>
        );
    };

  const renderFileDownload = (file) => {
      return (
          <a
              className="cursor-pointer"
              onClick={() => downloadFileBinary(file.id, file.name)}
          >
              <FontAwesomeIcon
                  icon={faDownload}
                  className="ml-2"
              />{" "}
              Tải file
          </a>
      )
  }

  const downloadFileBinary = (id, name) => {
        if (!id) return;
        documentAction.DownloadFile(id).then((res) => {
            const blob = new Blob([res], {
                type: 'application/*',
            });
            saveAs(blob, name);
        })
    }

  return (
    <div className="wrapper">
      <div className="row records-management mt-4 mb-4 ml-n2 mr-n2">
        {!isShowPreview && (
          <div
            id="leftPanel"
            className="col-12 col-sm-12 col-md-12 col-lg-3 pl-2 pr-2"
          >
            <div className="planning-records-search">
              <div className="title-records">
                <span>
                  <FontAwesomeIcon icon={faCopy} className="mr-2" />
                  Hồ sơ quy hoạch
                </span>
              </div>
              <div className="content-records m-3">
                  <DocSearchView />
              </div>
                <div className="content-records m-3">
                    <DocTreeView
                    id={id}/>
                    <div className="cb"></div>
                </div>
            </div>
          </div>
        )}
        <div
          className={`col-12 col-sm-12 col-md-12 ${
            isShowPreview ? "col-lg-6" : "col-lg-9"
          } pl-2 pr-2`}
        >
          <div style={{ background: "#f9f9f9" }} className="p-3">
              {
                  Array.isArray(listBredScrums) && listBredScrums.length >0 && (
                      <div className="document-folder-name">
                          <FontAwesomeIcon
                              icon={faFolderOpen}
                              className={"mr-2" + (isTable ? " active" : "")}
                              onClick={() => setIsTable(true)}
                          />
                          {listBredScrums[listBredScrums.length - 1].title}
                      </div>
                  )
              }
            {!isDetail && (
              <div className="planning-records-result">
                <div className="planning-records-title border-bottom text-right mb-3">
                  <FontAwesomeIcon
                    icon={faTable}
                    className={"mr-2" + (isTable ? " active" : "")}
                    onClick={() => setIsTable(true)}
                  />
                  <FontAwesomeIcon
                    icon={faList}
                    className={!isTable ? " active" : ""}
                    onClick={() => setIsTable(false)}
                  />
                </div>
                <div
                  className={
                    "records-table" + (isTable ? " d-block" : " d-none")
                  }
                >
                  <div className="row flex-row">
                    {listDocuments && listDocuments.length > 0 ? (
                      listDocuments.map((item, index) => (
                        <div
                          className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3"
                          key={index}
                        >
                          <div className="row">
                            <div className="col-4 pr-0">
                                {
                                    config.ImageSupport.includes(item.extension) ? (
                                        <img
                                            src={config.APIUrlDefault + item.path}
                                            alt={item.title}
                                            className="img-fluid w-100"
                                        />
                                    ):(
                                        <img
                                            src={require("./../../assets/image/file-thumbnail.png")}
                                            alt={item.title}
                                            className="img-fluid w-100"
                                        />
                                    )
                                }

                            </div>
                            <div className="col-8">
                              <span className="title">{item.name}</span>
                              { <div>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="mr-2 icon"
                                />
                                <span>
                                  { moment(new Date(item.modified_date)).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </div>}
                              <div>
                                  {renderFilePreview(item)}
                                  {renderFileDownload(item)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-12 text-center text-danger mb-3">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mr-2"
                        />{" "}
                        Không có hồ sơ nào
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    "records-list" + (!isTable ? " d-block" : " d-none")
                  }
                >
                  <table className="table table-striped table-responsive">
                    <tbody>
                      {listDocuments && listDocuments.length > 0 ? (
                        listDocuments.map((item, index) => (
                          <tr key={index}>
                            <th scope="row" className="align-middle">
                                <span>{item.name}</span>
                            </th>
                            <td className="align-middle">
                                {renderFilePreview(item)}
                            </td>
                            <td className="align-middle">
                                {renderFileDownload(item)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="text-center text-danger">
                              <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="mr-2"
                              />{" "}
                              Không có hồ sơ nào
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {paginationNumber && paginationNumber.totalItemCount > 0 && (
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
                      <div className="list-footer mb-4">
                        <div className="text-result float-left">
                          <span className="ml-2">
                            Có {paginationNumber.totalItemCount} kết quả
                          </span>
                        </div>
                        <div className="float-right">
                          <Pagination
                            totalItems={paginationNumber.totalItemCount}
                            currentIndex={paginationNumber.pageIndex}
                            pageSize={paginationNumber.pageSize}
                            onClick={doPaging}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
              {isShowPreview && (
                  <div
                      className="close-preview-file"
                      onClick={() => setShowPreview(false)}
                  >
                      <FontAwesomeIcon icon={faWindowClose} color="#000000" fontSize="5" />
                  </div>
              )}
          </div>
        </div>
          {isShowPreview && (
              <div
                  className={`col-12 col-sm-12 col-md-12 col-lg-6 pl-2 pr-2`}
                  style={{ height: window.innerHeight }}
              >
                  {console.log(file)}
                  {file ? (
                      <FileViewer
                          key={file.id}
                          fileType={file.extension.toLowerCase()}
                          filePath={config.APIUrlDefault + file.path}
                          errorComponent={
                              <div className="mt-4 d-flex align-items-center justify-content-center">
                                  <CancelPresentationIcon className="text-danger mr-1" /> Lỗi
                                  đọc file
                              </div>
                          }
                          unsupportedComponent={
                              <div className="mt-4 d-flex align-items-center justify-content-center">
                                  <CancelPresentationIcon className="text-danger mr-1" /> File
                                  không được hỗ trợ
                              </div>
                          }
                      />
                  ) : (
                      <div className="mt-4 d-flex align-items-center justify-content-center">
                          <CancelPresentationIcon className="text-danger mr-1" /> Không có
                          file để hiển thị
                      </div>
                  )}
              </div>
          )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    listDocuments: state.doc.listDocuments,
    listBredScrums:state.doc.listBredScrums,
    paginationNumber: state.doc.paginationNumber,
    folderId: state.doc.folderId,
    dataSearch: state.doc.dataSearch,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        showLoading: appActions.ShowLoading,
        GetListDocumentByFolder: documentAction.GetListDocumentByFolder,
        setCurrentFolderId: documentAction.setCurrentFolderId,
        SetDataSearch: documentAction.SetDataSearch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentManagementDesktopView);
