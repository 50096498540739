import React, {useEffect, useState, useRef, PureComponent} from "react";
import {connect, useSelector} from "react-redux";
import { bindActionCreators } from "redux";
import './leftmenu.scss';
import * as documentAction from "./../../../redux/store/doc/document.store";

import * as appActions from "./../../../core/app.store";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useForm} from "react-hook-form";
import * as config from "../../../utils/configuration";

function DocSearchView(props: any) {
    const {showLoading, GetListDocumentByFolder} = props;
    const { register, handleSubmit } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const currentPageIndex  = 1;
    const currentPageSize   = config.defaultPagesize;
    const [documentType, setDocumentType] = useState([]);
    const [yearStatement, setYearStatement] = useState([]);
    const [documentTypeId, setDocumentTypeId] = useState();
    const [districtSearch, setDistrictSearch] = useState("");
    const [yearSearch, setYearSearch] = useState("");
    const [district, setDistrict] = useState([]);
    const [dataSearching, SetDataSearching] = useState(null);
    const [actionType, setActionType] = useState('');
    const {id,folderId,setCurrentFolderId, SetDataSearch} = props;

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            GetListServiceLink(),
            GetYearStatement(),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
                config.HandleError(err);
            });
    };

    const onSubmit = (data:any) => {
        setCurrentFolderId(0);
        setActionType('search');
        data.actionType     = 'search';
        data.parentId       = 0;
        data.pageIndex      = currentPageIndex;
        data.districtIds    = districtSearch;
        data.years          = yearSearch;
        data.pageSize       = config.defaultPagesize;

        SetDataSearch(data);

        GetListDocumentByFolder(data);
    };

    const GetYearStatement = () => {
        return new Promise((resolve, reject) => {
            documentAction
                .GetYearStatement()
                .then((res:any) => {
                    setYearStatement(
                        res && res.content && res.content.length > 0
                            ? res.content.map((item:any) => {
                                return { ...item, label: item, value: item };
                            })
                            : []
                    );
                    resolve(res);
                })
                .catch((err:any) => reject(err));
        });
    };

    const GetListServiceLink = () => {
        return new Promise((resolve, reject) => {
            documentAction
                .GetLockUpDistrict()
                .then((res:any) => {
                    setDistrict(
                        res && res.content && res.content.length > 0
                            ? res.content.map((item:any) => {
                                return { ...item, label: item.name, value: item.id };
                            })
                            : []
                    );
                    resolve(res);
                })
                .catch((err:any) => reject(err));
        });
    };

    const handleOnChangeDistrict = (data:any) => {
        var arrDistrict: any = [];
        if (data) {
            setDistrictSearch(
                data
                    .map((item:any) => {
                        arrDistrict.push(item.id);
                    })
            );
        }
        setDistrictSearch(arrDistrict);
    };

    const handleOnChangeYears = (data:any) => {
        var years : any = [];
        if (data) {
            setYearSearch(
                data
                    .map((item:any) => {
                        years.push(item.value);
                    })
            );
        }

        setYearSearch(years);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group mb-3">
                <input
                    type="text"
                    name="documentName"
                    ref={register}
                    className="form-control"
                    placeholder="Tìm kiếm hồ sơ"
                    aria-label="Tìm kiếm hồ sơ"
                    aria-describedby="documentName"
                />
            </div>
            {district && district.length > 0 && (
                <Select
                    className="basic-single mb-3"
                    classNamePrefix="select"
                    placeholder="Chọn quận/huyện"
                    name="district"
                    isMulti
                    onChange={handleOnChangeDistrict}
                    options={district}
                    noOptionsMessage={()=>'Quận /Huyện không tồn tại'}
                />
            )}
            <Select
                className="basic-single mb-3"
                classNamePrefix="select"
                placeholder="Chọn năm"
                name="year"
                isMulti
                onChange={handleOnChangeYears}
                options={yearStatement}
            />
            <div className="input-group mb-3 mt-3">
                <button
                    type="submit"
                    className="text-uppercase btn btn-info w-100"
                >
                    <FontAwesomeIcon icon={faSearch} className="mr-1" />
                    Tìm kiếm
                </button>
            </div>
        </form>
);
}

const mapStateToProps = (state: any) => ({
    folderId: state.doc.folderId,
});
const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators({
        showLoading: appActions.ShowLoading,
        GetListDocumentByFolder: documentAction.GetListDocumentByFolder,
        setCurrentFolderId: documentAction.setCurrentFolderId,
        SetDataSearch: documentAction.SetDataSearch,
}, dispatch);

export default (connect(mapStateToProps, mapDispatchToProps)(DocSearchView));
