import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const MapFilter = (data) => {
    return service.post(ApiUrl.MapFilter, data).then((res) => {return res}).catch((err) => { throw err });
}
export const GetDropDown = (data) => { 
    return service.post(ApiUrl.GetDropDown, data).then((res) => {return res}).catch((err) => { throw err });
}
export const SpatialSearch = (data) => { 
    return service.post(ApiUrl.SpatialSearch, data).then((res) => {return res}).catch((err) => { throw err });
}