import React, { useState } from "react";
import { connect } from "react-redux";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { LoadScript } from "@react-google-maps/api";

import * as configuration from "../../../../utils/configuration";

import "./search-position.scss";
import {bindActionCreators} from "redux";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";



function SearchByPosition(props: any) {
  const { onSelectAddress } = props;
    // Set default data search
    props.handleSearchAddressChange({
        location: MapDataStore.getLatLong(''),
        action:''
    });

  const handleChange = (event:any) => {
    props.handleSearchAddressChange({
        location: MapDataStore.getLatLong(event.target.value),
        action:''
    });
  };

  const handleKeyPress = (event:any) => {
      if (event.key === 'Enter') {
          props.handleSearchAddressChange({
              location: MapDataStore.getLatLong(event.target.value),
              action:'search'
          });
      }
  };

  return (
      <div className="map__search-bar-container">
        <div className="map__search-input-container">
          <input
                placeholder = "Nhập tọa độ VN2000 (Tọa độ X,Y)"
                className = "input-search form-control"
                onChange= {handleChange}
                onKeyPress={handleKeyPress}
          />
        </div>
      </div>
  );
}

const mapStateToProps = (state: any) => ({
  openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch:any) => bindActionCreators({
    handleSearchAddressChange : MapDataStore.handleSearchAddressChange,
}, dispatch)

export default connect(mapStateToProps,mapDispatchToProps)(SearchByPosition);
