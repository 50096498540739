import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import GuideUseMap from "../../guide-use-map/guide-use-map.view";

function Guide(props) {
    return(
        <div
            className="modal fade bd-example-modal-lg"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div
                    className="modal-content modal-content-custom"
                    style={{ height: `${props.modalHeightStyle}px` }}
                >
                    <div className="modal-content-header text-center position-relative">
                        Hướng dẫn sử dụng
                        <FontAwesomeIcon
                            type="button"
                            data-dismiss="modal"
                            aria-label="Close"
                            icon={faTimes}
                            color="white"
                            size="lg"
                        />
                    </div>
                    <GuideUseMap />
                </div>
            </div>
        </div>
    );

}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Guide);