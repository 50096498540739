import React, {useEffect, useState, useRef} from "react";
import {connect, useSelector} from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import { geolocated } from "react-geolocated";

import UrlCollect from "../../../../common/url-collect";
import history from "../../../../common/history";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";
import {CoordsModel} from "../../../../models/planning-map-view/top-right-panel-models";
import {isMobile} from 'react-device-detect';


function SearchPlanningView(props: any) {

  const getLayerList = () => {
    if (Array.isArray(props.listGroupLayer)) {
      const parent = props.listGroupLayer.filter((item: any) => item.layer_settings.length > 0)

      let options: { label: string, value: string | number }[] = []
      for (let i=0; i < parent.length; i++) {
        const layer_settings = parent[i]['layer_settings']
        layer_settings.forEach((item: any) => {
          options.push({
            label: item.geo_layer_name,
            value: item.id,
          })
        })
      }
      return options
    }
    return []
  }

  const setSelected = (data:any) => {
    let selectedId = data.value;
    if (Array.isArray(props.listGroupLayer)) {
      const parent = props.listGroupLayer.filter((item: any) => item.layer_settings.length > 0)
      for (let i = 0; i < parent.length; i++) {
        const layer_settings = parent[i]['layer_settings']
        layer_settings.forEach((item: any) => {
          let element: any = document.getElementById('item_'+item.id);
          let chkEconomicCurrent: any = document.getElementById('chkEconomicCurrent');
          let chkEconomicPlan: any = document.getElementById('chkEconomicPlan');
          chkEconomicCurrent.checked = false;
          chkEconomicPlan.checked = false;
          if(item.id === selectedId){
            if(element){
              element.checked = true;
            }
            item.is_check   = true;
          }else{
            if(element){
              element.checked = false;
            }
            item.is_check   = false;
          }
          props.openLayerController?.toggleDisplayLayer(item);
          if(isMobile){
            props.toggleLeftNavBarAction();
          }
        })
      }
    }
  }

  return (
    <div className="row no-gutters align-items-center">
      <div className="col-10">
        <Select
          options= {getLayerList()}
          onChange={(newValue) => setSelected(newValue)}
          placeholder="Nhập tên lớp bản đồ"
          className="slAutocomplete"
          classNamePrefix="select"
        />
      </div>
      <div className="col-2 text-right">
        <button
          className="bg-transparent border-0"
        >
          <img
            src={require("../../../../assets/icon/locate.svg")}
            alt="Planning Name"
          />
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  openLayerController: state.openlayer.openLayerController,
});
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  handleSearchLayerChange: MapDataStore.handleSearchLayerChange,
}, dispatch);

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(SearchPlanningView));
