import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from "../../../core/app.store";
import * as documentAction from "../../../redux/store/doc/document.store";
import * as config from "../../../utils/configuration";
import {useHistory} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import Folder from '@material-ui/icons/Folder';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

function DocTreeView(props) {
    let history = useHistory();
    const {
        showLoading,
        SetDataSearch
    } = props;

    const currentPageIndex    = 1;
    const currentPageSize     = config.defaultPagesize;
    const [documentFolderList, setDocumentFolderList] = useState([]);

    useEffect(() => {
        GetAllDocumentFolder();
    }, []);

    const GetAllDocumentFolder = () => {
        showLoading(true);
        documentAction
            .GetAllDocumentFolder()
            .then((result) => {
                if (result && result.content) {
                    setDocumentFolderList(
                        result.content && result.content.length > 0
                            ? result.content
                            : []
                    );
                }
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const getTreeDocItem = (item) => {
        if(item.nodeId >0){
            props.doSearchDocument(item.nodeId,currentPageIndex,currentPageSize);
            props.setCurrentFolderId(item.nodeId);
            props.SetDataSearch({'actionType':'list'});
        }
    }

    const useTreeItemStyles = makeStyles((theme) => ({
        root: {
            color: theme.palette.text.secondary,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover,
            },
            '&:focus > $content, &$selected > $content': {
                backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
                color: 'var(--tree-view-color)',
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent',
            },
        },
        content: {
            color: theme.palette.text.secondary,
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium,
            '$expanded > &': {
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
        group: {
            marginLeft: 0,
            '& $content': {
                paddingLeft: theme.spacing(2),
            },
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0),
        },
        labelIcon: {
            marginRight: theme.spacing(1),
            color:'#E3A42E',
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
        },
    }));

    function StyledTreeItem(props) {
        const classes = useTreeItemStyles();
        const { labelText, labelIcon: FolderIcon, labelInfo, color, bgColor, ...other } = props;

        return (
            <TreeItem
                onClick={() => {getTreeDocItem(props)}}
                label={
                    <div className={classes.labelRoot}>
                        <FolderIcon color="inherit" className={classes.labelIcon} />
                        <Typography variant="body2" className={classes.labelText}>
                            {labelText}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </div>
                }
                style={{
                    '--tree-view-color': color,
                    '--tree-view-bg-color': bgColor,
                }}
                classes={{
                    root: classes.root,
                    content: classes.content,
                    expanded: classes.expanded,
                    selected: classes.selected,
                    group: classes.group,
                    label: classes.label,
                }}
                {...other}
            />
        );
    }

    StyledTreeItem.propTypes = {
        bgColor: PropTypes.string,
        color: PropTypes.string,
        labelIcon: PropTypes.elementType.isRequired,
        labelInfo: PropTypes.string,
        labelText: PropTypes.string.isRequired,
    };

    const useStyles = makeStyles({
        root: {
            height: 264,
            flexGrow: 1,
            maxWidth: 400,
        },
    });

    const classes = useStyles();
    
    function renderTreeFolder(docList) {
        return (
            Array.isArray(docList) && docList.length >0 && (
                docList.map((item) => (
                    item.type == 0 && (
                        Array.isArray(item.childrent)  && item.childrent.length >0 ? (
                            <StyledTreeItem
                                nodeId={item.id}
                                labelText={item.name}
                                labelIcon={Folder}
                                bgColor="#FFFFFF"
                            >
                                {renderTreeFolder(item.childrent)}
                            </StyledTreeItem>
                        ):(
                            <StyledTreeItem nodeId={item.id} labelText={item.name} labelIcon={Folder}></StyledTreeItem>
                        )
                    )
                ))
            )
        );
    }

    return (
        <TreeView
            className={classes.root}
            defaultExpanded={['3']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
        >
            {renderTreeFolder(documentFolderList)}
        </TreeView>
    );
}

const mapStateToProps = (state) => ({
    dataSearch: state.doc.dataSearch,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    showLoading: appActions.ShowLoading,
    doSearchDocument: documentAction.doSearchDocument,
    setCurrentFolderId: documentAction.setCurrentFolderId,
    SetDataSearch: documentAction.SetDataSearch,
}, dispatch);

export default (connect(mapStateToProps, mapDispatchToProps)(DocTreeView));