import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Collapsible from "react-collapsible";
import MenuItem from "./item";
import LayerSettingsModels from "../../../../models/map-data-model-b/layer-setting-models";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";

function Category(props) {
    function handleToggleDisplayLayer(
        layerTarget: LayerSettingsModels.LayerSettingsModel
    ) {
        props.openLayerController?.toggleDisplayLayer(layerTarget);
    }

    function handleSaveMapNote(data) {
        props.SaveMapNote(data);
    }

    return(
        <div>
            {
                Array.isArray(props.listLayerCategoryParent) && props.listLayerCategoryParent.length >0 && (
                    props.listLayerCategoryParent.map((p2Item,p2Id) => (
                        p2Item.parentId == props.pItem.id && (
                            <summary>
                                <img
                                    id={"level2-arrow-down"+p2Item.id}
                                    className="position-absolute level2-arrow-down level2-right-position"
                                    src={require("../../../../assets/icon/arrow-down-2.png")}
                                    alt="Arrow Down"
                                />
                                <img
                                    id={"level2-arrow-up"+p2Item.id}
                                    className="position-absolute level2-arrow-up level2-right-position"
                                    src={require("../../../../assets/icon/arrow-up-2.png")}
                                    alt="Arrow Right"
                                />
                                <Collapsible className="left-menu-level-2"
                                             trigger={p2Item.category_parent_name}
                                             onTriggerOpening   = {() => {
                                                 props.setMenuIconL2(p2Item.id,'open');
                                             }}
                                             onTriggerClosing    = {() => {
                                                 props.setMenuIconL2(p2Item.id,'close');
                                             }}
                                >
                                    {
                                        props.listGroupLayer.map((data, index: number) => (
                                            data.category_parent_id == p2Item.id && (
                                                <MenuItem
                                                    toggleLayerAction={(
                                                        data: LayerSettingsModels.LayerSettingsModel
                                                    ) => handleToggleDisplayLayer(data)}
                                                    setMapNote={(data:any) => handleSaveMapNote(data)}
                                                    toggleLeftNavBarAction={() => props.handleClickToggleLeftNavBar()}
                                                    key={index}
                                                    data={data}
                                                    parentId={p2Item.id}
                                                    openLayerController={props.openLayerController}
                                                />
                                            )
                                        ))
                                    }
                                    <div className="left-menu-blank-line"></div>
                                </Collapsible>
                            </summary>
                        )
                    ))
                )
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            SaveMapNote:MapDataStore.SaveMapNote,
        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Category);