import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import { geolocated } from "react-geolocated";

import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
import PlanningMapViewModel from "../../../models/planning-map-view/planning-map-view.model";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import * as mapHelper from "../../../common/map";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../../utils/configuration";

function SearchPlanningView(props: any) {
  const [dataSelectBox, setDataSelectBox] = useState<any[]>([]);

  useEffect(() => {
    convertSelectBoxData();
  }, [props.listBoundaries])
  function convertSelectBoxData() {
    if (props.listBoundaries && props.listBoundaries.length > 0) {
      const _newDataSelectBox: any[] = [];
      props.listBoundaries.map((boundaryModel: PlanningMapViewModel.BoundaryModel) => {
        _newDataSelectBox.push({ value: boundaryModel.mapCenter, label: boundaryModel.name })
      })
      setDataSelectBox(_newDataSelectBox)
    }
  }

  function handleClickCurrentLocation() {
    navigator.geolocation.getCurrentPosition((position: any) => {
      if (position && position.coords && checkAddressInPolyon(position.coords.longitude, position.coords.latitude)) {
        placeSearch(position.coords.latitude, position.coords.longitude)
      }
    }, (error) => {
      ShowNotification(
        viVN.Errors.OpenCoordinateCurrent,
        NotificationMessageType.Error,
        NotificationPosition.TopRight
      );
    });
  }
  function placeSearch(lat: any, lng: any) {
    history.push(
      `${UrlCollect.SyntheticPlanning}?lat=${lat}&lng=${lng}&searchType=place`
    );
    if (
      props.openLayerController &&
      props.openLayerController.placeSearch
    ) {
      props.openLayerController.placeSearch([
        lng,
        lat
      ]);
    }
  }
  function setSelected(newValue: any) {
    if (!newValue.value) {
      console.log('Missing coordinates: ' + newValue.label)
      return;
    }
    const value = newValue.value.split(',');
    if (!value || value.length === 0) return;
    let x = parseFloat(value[1]);
    let y = parseFloat(value[0]);
    placeSearch(x, y)
  }

  function checkAddressInPolyon(x: any, y: any) {
    let isInside = false;
    props.listPolygon &&
      props.listPolygon.map((itemPolygon: any) => {
        if (
          mapHelper.isPointInPolygon(y, x, itemPolygon)
        ) {
          isInside = true;
          return;
        }
      });
    if (isInside) {
      history.push(
        `${UrlCollect.SyntheticPlanning}?lng=${x}&lat=${y}&searchType=place`
      )
      return true;
    } else {
      ShowNotification(
        viVN.Errors.CoordinateOutSidePolygon,
        NotificationMessageType.Warning,
        NotificationPosition.Center
      );
    }
    return false;
  }

  return (
    <div className="row no-gutters align-items-center">
      <div className="col-10">
        <Select
          options={dataSelectBox}
          onChange={(newValue) => setSelected(newValue)}
          placeholder="Nhập tên quy hoạch - dự án"
          className="slAutocomplete"
          classNamePrefix="select"
        />
      </div>
      <div className="col-2 text-right">
        <button
          className="bg-transparent border-0"
          onClick={handleClickCurrentLocation}
        >
          <img
            src={require("../../../assets/icon/locate.svg")}
            alt="Planning Name"
          />
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  openLayerController: state.openlayer.openLayerController,
  listBoundaries: state.mapData.listBoundaries,
  listPolygon: state.mapData.listPolygon,
});
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  GetAllPlanningBoundaries: MapDataStore.GetAllPlanningBoundaries,
}, dispatch);

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(SearchPlanningView));
