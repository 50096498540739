import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import LayerCategoryModels from "../../../../models/map-data-model-b/layer-category-models";
import LayerSettingsModels from "../../../../models/map-data-model-b/layer-setting-models";
import {isMobile} from "react-device-detect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

function Item(props: any) {
    const data: LayerCategoryModels.LayerCategoryModel = props.data;
    const [layerSetting, setLayerSetting] = useState<
        LayerSettingsModels.LayerSettingsModel[]
        >(props.data.layer_settings);

    function toggleSelectAll() {
        if (isEveryCheckboxChecked()) {
            selectOrDeselectAllCheckBox(false);
        } else {
            selectOrDeselectAllCheckBox();
        }
    }

    function isEveryCheckboxChecked() {
        let result = true;
        const listCheckbox = Array.from(
            document.querySelectorAll(`input[data-select-id="${data.folder_name}"]`)
        );
        listCheckbox.map((checkbox) => {
            if (!(checkbox as HTMLInputElement).checked) {
                result = false;
                return true;
            }
        });
        return result;
    }
    function selectOrDeselectAllCheckBox(isSelect = true) {
        const listCheckbox = Array.from(
            document.querySelectorAll(`input[data-select-id="${data.folder_name}"]`)
        );
        listCheckbox.map((checkboxElement) => {
            const checkbox = checkboxElement as HTMLInputElement;
            if (checkbox.checked != isSelect) checkbox.click();
        });
    }

    function handleClickCheckbox(item, LayerIndex: number, newValue: boolean) {
        const checkboxAll = document.querySelectorAll(
            `input[data-select-all-id="${data.folder_name}"]`
        )[0] as HTMLInputElement;
        const oldLayerSetting = [...layerSetting];
        oldLayerSetting[LayerIndex].is_check = newValue;
        setLayerSetting(oldLayerSetting);
        props.toggleLayerAction(oldLayerSetting[LayerIndex]);
        if(!newValue){
            props.setMapNote({});
            props.openLayerController.removeHiglightVectorLayer && props.openLayerController.removeHiglightVectorLayer();
        }

        if(isMobile){
            props.toggleLeftNavBarAction();
        }
        /*if (checkboxAll) {
          if (isEveryCheckboxChecked()) {
            checkboxAll.checked = true;
            setLabelFlexible("Bỏ chọn tất cả");
          } else {
            checkboxAll.checked = false;
            setLabelFlexible("Chọn tất");
          }
        }*/
    }

    function setMapNote(item,LayerIndex,isChecked) {
        if(!isChecked){
            handleClickCheckbox(item, LayerIndex,true);
        }
        props.setMapNote(item);
    }

    return (
        <div>
            <div className="left-menu-folder-box">
                {data.folder_name}
            </div>
            {data.layer_settings.length > 0 && (
                <div className="left-menu-item-box">
                    {
                        Array.isArray(layerSetting) && layerSetting.length >0 && (
                            layerSetting.map((item, index: number) => (
                                <div className="left-menu-item-container" key={index}>
                                    <input
                                        id ={'item_'+item.id}
                                        type="checkbox"
                                        data-select-id={data.folder_name}
                                        checked={item.is_check}
                                        onChange={() => {}}
                                        data-left-menu-layer-id={item.geo_layer_name}
                                        className="mr-1"
                                        onClick={() => handleClickCheckbox(item, index, !item.is_check)}
                                    />
                                    <a className="left-menu-link" onClick={() => handleClickCheckbox(item, index, !item.is_check)}>
                                        {item.geo_layer_name}
                                    </a>
                                    {item.files != null && item.files.filePreview && (
                                        <i className="map-note-icon" onClick={() => setMapNote(item,index,item.is_check)}>
                                            &nbsp;
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                color="#1075bd"
                                            />
                                        </i>
                                    )}
                                </div>
                            ))
                        )
                    }
                    <hr className="mb-0 mt-0" />
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Item);