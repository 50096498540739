import React, { useState } from "react";
import { connect } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { LoadScript } from "@react-google-maps/api";

import * as configuration from "../../../../utils/configuration";

import "./search-address.scss";

function SearchByAddress(props: any) {
  const { onSelectAddress } = props;
  const [address, setAddress] = useState("");
  const handleChange = (address: any) => {
    setAddress(address);
  };
  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let value = latLng.lat + ", " + latLng.lng;
        props.openLayerController &&
          props.openLayerController.placeSearch([latLng.lng, latLng.lat]);
        onSelectAddress && onSelectAddress(latLng.lng, latLng.lat);
      })
      .catch((error) => console.error("Error", error));
  };

  const classnames = (...args: any) => {
    const classes: any = [];
    args.forEach((arg: any) => {
      if (typeof arg === "string") {
        classes.push(arg);
      } else if (typeof arg === "object" && arg !== null) {
        Object.keys(arg).forEach((key) => {
          if (arg[key]) {
            classes.push(key);
          }
        });
      } else {
        throw new Error(
          "`classnames` only accepts string or object as arguments"
        );
      }
    });

    return classes.join(" ");
  };
  return (
    // <LoadScript
    //   id="google-map-loader"
    //   googleMapsApiKey={configuration.GoogleMapAPI}
    //   region={configuration.region}
    //   language={configuration.language}
    //   libraries={configuration.libraries}
    // >
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="map__search-bar-container">
          <div className="map__search-input-container">
            <input
              {...getInputProps({
                placeholder: "Nhập địa điểm tra cứu",
                className: "input-search form-control",
              })}
            />
          </div>
          {suggestions.length > 0 && (
            <div className="map__autocomplete-container">
              {suggestions.map((suggestion: any, index: number) => {
                const className = classnames("map__suggestion-item", {
                  "map__suggestion-item--active": suggestion.active,
                });
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
    // </LoadScript>
  );
}
const mapStateToProps = (state: any) => ({
  openLayerController: state.openlayer.openLayerController,
});
export default connect(mapStateToProps)(SearchByAddress);
