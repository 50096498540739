import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import UrlCollect from "../../../common/url-collect";
import * as statementAction from "../../../redux/store/statement/statement.store";
import * as configuration from "../../../utils/configuration";

import "./menu-right.scss";

export default function MenuRightView(props) {
  const [serviceModels, setServiceModels] = useState(null);

  useEffect(() => {
    statementAction
      .GetSerivceLink()
      .then(
        (res) =>
          res &&
          res.content &&
          res.content.linkGroup &&
          setServiceModels(res.content.linkGroup)
      )
      .catch((err) => {});
  }, []);

  return (
      <div className="right-menu-link-list">
        {serviceModels &&
          serviceModels.map((items, index) =>
            items.displayType === "IMAGE" ? (
              <div className="item mb-1">
                {items.linkService &&
                  items.linkService.length > 0 &&
                  items.linkService.map((linkService, index1) => (
                    <div className="row" key={index1}>
                      <div className="col-12 mt-1">
                        <a
                          href={linkService.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={
                              configuration.APIUrlDefault + linkService.image
                            }
                            alt="Map"
                            className="img-fluid w-100 mb-2 mb-md-0"
                          />
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="mt-1">
                <div className="header-result">
                  <div className="title">
                    <a
                      className="text-uppercase"
                      data-toggle="collapse"
                      href={"#Parent" + index}
                      role="button"
                      aria-expanded="false"
                      aria-controls="department"
                    >
                      <FontAwesomeIcon
                        icon={faAngleDoubleDown}
                        className="mr-2"
                      />
                      {items.name}
                    </a>
                  </div>
                </div>
                {items.linkService &&
                  items.linkService.length > 0 &&
                  items.linkService.map((itemsChild) => (
                    <div className="collapse" id={"Parent" + index}>
                      <div className="content-result mt-1">
                        <ul>
                          <li>
                            <a
                              href={itemsChild.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {itemsChild.name}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
              </div>
            )
          )}
      </div>
  );
}
