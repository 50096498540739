import { combineReducers } from "redux";
import MapToolsPanelReducers from "./store/open-layer/map-tools-panels.store";
import AppReducer from "../core/app.store";
import LoadingReducer from "../redux/store/loading/loading.store";
import MapDataReducer from "./store/map-data/map-data.store";
import OpenLayerReducer from "./store/open-layer/open-layer.store";
import StatementReducer from "./store/statement/statement.store";
import DocumentReducer from "./store/doc/document.store";

export default combineReducers({
  app: AppReducer,
  mapToolsPanel: MapToolsPanelReducers,
  loadingState: LoadingReducer,
  mapData: MapDataReducer,
  openlayer: OpenLayerReducer,
  statement: StatementReducer,
  doc:DocumentReducer
});
