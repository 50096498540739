/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./pagination.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

export default function PaginationView(props) {
  const pageRangeDisplayed = props.pageRangeDisplayed || 5;
  const pageTotal =
    props.pageTotal || Math.ceil((props.totalItems || 0) / props.pageSize) || 1;

  const [leftPage, setLeftPage] = useState(1);
  const [rightPage, setRightPage] = useState(pageRangeDisplayed);
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex || 1);

  const handleOnClick = function (index) {
    setCurrentIndex(index);
    if (index <= pageRangeDisplayed) {
      setLeftPage(1);
      setRightPage(pageRangeDisplayed);
    } else if (index > pageTotal - pageRangeDisplayed) {
      setLeftPage(pageTotal + 1 - pageRangeDisplayed);
      setRightPage(pageTotal);
    } else {
      setLeftPage(index - Math.ceil((pageRangeDisplayed - 1) / 2));
      setRightPage(index + Math.ceil((pageRangeDisplayed - 1) / 2));
    }
    props.id ? props.onClick(props.id, index) : props.onClick(index);
  };

  const renderListButtonDisplay = () => {
    const listButton = [];
    for (let i = leftPage; i <= rightPage; i++)
      if (i <= pageTotal)
        listButton.push(
          <li
            key={i}
            className={`page-item ${
              currentIndex === i ? "active disabled" : ""
            }`}
          >
            <a className="page-link" href="#" onClick={() => handleOnClick(i)}>
              {i}
            </a>
          </li>
        );
      else break;
    return listButton;
  };

  return (
    <div>
      <nav className="pagination-custom-container">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentIndex === 1 ? "disabled" : ""}`}>
            <a className="page-link" href="#" onClick={() => handleOnClick(1)}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} color="black" />
            </a>
          </li>
          <li className={`page-item ${currentIndex === 1 ? "disabled" : ""}`}>
            <a
              className="page-link"
              href="#"
              onClick={() => handleOnClick(currentIndex - 1)}
            >
              <FontAwesomeIcon icon={faAngleLeft} color="black" />
            </a>
          </li>
          {renderListButtonDisplay()}
          <li
            className={`page-item ${
              currentIndex === pageTotal ? "disabled" : ""
            }`}
          >
            <a
              className="page-link"
              href="#"
              onClick={() => handleOnClick(currentIndex + 1)}
            >
              <FontAwesomeIcon icon={faAngleRight} color="black" />
            </a>
          </li>
          <li
            className={`page-item ${
              currentIndex === pageTotal ? "disabled" : ""
            }`}
          >
            <a
              className="page-link"
              href="#"
              onClick={() => handleOnClick(pageTotal)}
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} color="black" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
