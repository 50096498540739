import React, { useState, useEffect } from 'react';
import {connect, useSelector} from 'react-redux';
import { geolocated } from "react-geolocated";
import OpenlayerController from "../../../../models/open-layer/openlayer-controller-custom.model";
import { CoordsModel } from '../../../../models/planning-map-view/top-right-panel-models';
import {bindActionCreators} from "redux";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";
import {isMobile} from 'react-device-detect';


interface InitProps {
    classCustom?: string;
    openLayerController?: OpenlayerController;
    onShow?: Function;
    show?: boolean;
    currentAddress?: any;
    toggleLeftNavBarAction: Function;
}

function SearchByMyPosition(props:any) {
    const { onShow } = props;
    const [currentPosition, setCurrentPosition] = useState<CoordsModel | undefined>(undefined);
    const searchInfo = useSelector((state: any) => state.mapData.searchPositionInfo)

    if(searchInfo.action === 'search'){
        handleClickCurrentLocation();
    }
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position: any) => {
            setCurrentPosition(position.coords);
        });

    }, [])

    function handleClickCurrentLocation() {
        onShow && onShow(!props.show);
        if(searchInfo.location.longitude && searchInfo.location.latitude){
            props.openLayerController?.placeSearch([searchInfo.location.longitude, searchInfo.location.latitude]);
            if(isMobile){
                props.toggleLeftNavBarAction();
            }
        }
    }

    return (
        <button onClick={handleClickCurrentLocation} className="bg-transparent border-0">
            <img src={require("../../../../assets/icon/planning-name.svg")} alt="Locate" />
        </button>
    )
}

const mapStateToProps = (state: any) => ({
    openLayerController: state.openlayer.openLayerController,
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    currentAddress: MapDataStore.GetCurrentAddess,
}, dispatch);

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps,mapDispatchToProps)(SearchByMyPosition));