import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const Login = (data) => {
  return service
    .post(ApiUrl.Login, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Register = (data) => {
  return service
    .post(ApiUrl.Register, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ForgotPassword = (data) => {
  return service
    .post(ApiUrl.ForgotPassword, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ResetPassword = (data) => {
  return service
    .post(ApiUrl.ResetPassword, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetUserAccountDetail = () => {
  return service
    .get(ApiUrl.GetUserAccountDetail)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const UpdateUserAccount = (data) => {
  const formData = new FormData();
  data && data.id && formData.append("Id", data.id);
  data && data.currentPassword && formData.append("CurrentPassword", data.currentPassword);
  data && data.newPassword && formData.append("NewPassword", data.newPassword);
  data && data.file && formData.append("File", data.file);
  return service
    .post(ApiUrl.UpdateUserAccount, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};